import React from 'react'
import Link from 'next/link'
// import styled from 'styled-components';

// const StyledLink = styled(NavLink)`
//   position: relative;
//   font-size: 1.15vmax;
//   font-weight: 400;
//   color: black;
//   width: 150px;
//   height: 100%;
//   text-decoration: none;
//   text-transform: uppercase;
//   transition: background 0.3s ease-in-out;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .${(props) => props.className}_bottomBar {
//     position: absolute;
//     bottom: 0px;
//     left: 50%;
//     right: 50%;
//     border-bottom: 3px solid #1a4790;
//     transition: all 0.2s ease-in-out;
//   }

//   &:hover,
//   &:active,
//   &.active {
//     color: #1a4790;
//     background-color: #fafafa;
//     //   border-bottom: 3px solid #1a4790;

//     .${(props) => props.className}_bottomBar {
//       // position: absolute;
//       // bottom: 0px;
//       left: 0;
//       right: 0;
//       // border-bottom: 3px solid #1a4790;
//     }
//   }

//   &.active {
//     background-color: #fff;
//   }
// `;

const Navlink = ({ label, className = 'navLink', ...rest }) => {
    return (
        <div className={className}>
            <Link {...rest} className={`${className}__label`}>
                <a>{label}</a>
            </Link>
            <div className={`${className}__bottomBar`} />
        </div>
    )
}

export default Navlink
