import React from 'react'

// TODO: Flush out footer with nav & social media links
// TODO: Maybe add minimized contact form?

const Footer = () => {
    return (
        <footer>
            <p>Made with ❤️ 2021 &copy; New Evolution Digital</p>
        </footer>
    )
}

export default Footer
