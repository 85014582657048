import React from 'react'
import NavLink from './navlink'

// TODO: Swap to mobile version @ 1199px wide.

const Header = () => {
    return (
        <header className="NavHeader">
            <div className="contentContainer nav">
                <NavLink exact activeClassName="active" href="/" label="Home" />
                <NavLink
                    activeClassName="active"
                    href="/services"
                    label="Services"
                />
                <div className="nav__logo">
                    <img
                        src="/assets/Blue.svg"
                        alt="Blue New Evolution Digital Logo"
                    />
                </div>
                <NavLink activeClassName="active" href="/about" label="About" />
                <NavLink
                    activeClassName="active"
                    href="/contact"
                    label="Contact"
                />
            </div>
        </header>
    )
}

export default Header
