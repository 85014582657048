import React from 'react'
import Head from 'next/head'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import '../styles/index.scss'
import Header from '../components/header'
import Footer from '../components/footer'
import { PopupWidget } from 'react-calendly'

const theme = createTheme()

function MyApp({ Component, pageProps }) {
    React.useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles)
        }
    }, [])

    return (
        <React.Fragment>
            <Head>
                <title>New Evolution Digital</title>
                <meta charSet="utf-8" />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png"
                />
                <link rel="manifest" href="site.webmanifest" />
                <meta
                    name="description"
                    content="A web developer team that cares about your business. Sales, revenue, and success starts with New Evolution Digital."
                />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
            </Head>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <Header />
                <div className="content">
                    <Component {...pageProps} />
                </div>
                <PopupWidget url="https://calendly.com/new-evo-digital" />
                <Footer />
            </ThemeProvider>
        </React.Fragment>
    )
}

export default MyApp
